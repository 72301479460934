// PaymentDetails.js
import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './SignUp.module.css';
import logo from '../YourFlockLogo.png';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY); 

const PaymentDetails = () => {
    const [email, setEmail] = useState('');
    const stripe = useStripe();
    const elements = useElements();
    const [serverMessage, setServerMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

        // Step 1: Import Data
        const location = useLocation();
        const userData = location.state; // This will have data from WhatsAppSignup (name, phoneNumber, messageTime)
    
        const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);  // Set loading to true
        if (!stripe || !elements) {
            return;
        }

        const card = elements.getElement(CardElement);
        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: card,
            billing_details: {
                email: email,
            },
        });
        if (result.error) {
            setIsLoading(false); // Reset loading before returning
            console.log(result.error.message);
        } else {
           const response = await fetch('/api/subscribe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    paymentMethodId: result.paymentMethod.id,
                    name: userData.name,
                    phoneNumber: userData.phoneNumber,
                    messageTime: userData.messageTime,
                    courseId: userData.courseId,
                    provider: userData.provider,
                }),
            });

            if (response.ok) {
                // console.log("response:", response);
                const data = await response.json();
                // console.log("data: ", data);

                if (data.requiresAction) {
            // Use stripe.confirmCardSetup since it's a setupIntent
            const { setupIntent, error } = await stripe.confirmCardSetup(data.clientSecret);

         if (error) {
             console.log(error.message);
             return;
         }
         
         // Ensure setupIntent.status is succeeded before redirecting
         if (setupIntent && setupIntent.status === 'succeeded') {
            const createUserResponse = await fetch('/api/subscribe/create-user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    name: userData.name,
                    phoneNumber: userData.phoneNumber,
                    messageTime: userData.messageTime,
                    customerId: data.customerId,
                    subscriptionId: data.subscriptionId,
                    paymentMethodId: data.paymentMethodId,
                    courseId: userData.courseId,
                    provider: userData.provider,
                }),
            });
            if (createUserResponse.ok) {
                const data = await createUserResponse.json();
                window.location.href = data.redirect; 
            } else {
                const errorData = await createUserResponse.json();
                console.error("Server responded with:", errorData);
            }

        } else {
           console.error("Unexpected setupIntent status:", setupIntent.status);
           window.location.href = `${data.redirect}?status=${setupIntent.status}`; 
         }
     } else {
         window.location.href = data.redirect;
     }
 } else {
     const errorData = await response.json();
     console.error("Server responded with:", errorData);
     setServerMessage(errorData.message || 'An unexpected error occurred.'); // Set server message on error
     return; // prevent further processing
 }
        }
        setIsLoading(false); // Reset loading at the end
    };

    return (
        <div className={isLoading ? `${styles.container} ${styles.loadingCursor}` : styles.container}>
<div className={styles.container}>
    <div className={styles.logoSpace}>
        <img src={logo} alt="Logo" className={styles.logo} />
    </div>
    
    <div className={styles.signupBox}>
        <h2 className={styles.header}>Subscribe to Your FLOCK AI Coach</h2>
        
        <form onSubmit={handleSubmit} className={styles.form}>
            <label className={styles.formLabel}>
                Enter your email:
                <input 
                    type="email" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    placeholder="Email" 
                    required 
                    className={styles.formInput} 
                />
            </label>
            
            <label className={styles.formLabel}>
                Payment details:
        <CardElement className={styles.cardElementWrapper}/>
            </label>
            
            {serverMessage && 
                <div className={styles.errorMessage}>
                    {serverMessage}
                </div>}

                <label className={styles.formSubLabel}>
                <strong>Subtotal:</strong> £1.99 + VAT / month, cancel anytime
<br />
<br />
                <strong>Total due today:</strong> £0.00 - The first 14 days free!
            </label>
            
            <div className={styles.buttonWrapper}>
                <button type="submit" disabled={!stripe} className={styles.whatsappButton}>Sign up with WhatsApp 😍</button>
            </div>
        </form>

        <p className={styles.terms}>
            By signing up, you agree to our 
            <a href='https://yourflock.co.uk/terms-and-conditions/' target='_blank' rel='noopener noreferrer' className={styles.termsLink}> Terms of Service </a> and 
            <a href='https://yourflock.co.uk/privacy-policy/' target='_blank' rel='noopener noreferrer' className={styles.termsLink}> Privacy Policy.</a>
        </p>

    </div>
</div>
</div>

    );
};

const PaymentPage = () => {
    return (
      <Elements stripe={stripePromise}>
        <PaymentDetails />
      </Elements>
    );
  }

  export default PaymentPage
