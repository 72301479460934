import React, { useState, useEffect } from 'react';
import styles from './SignUp.module.css';
import logo from '../YourFlockLogo.png';
import { loadStripe } from '@stripe/stripe-js';
import { useLocation, useNavigate } from 'react-router-dom';


const SubscriptionConfirmationPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get('status');
    console.log("location: ", location);
    console.log("queryParams: ", queryParams);
    console.log("status: ", status)


    return (
        <div>
            <h1>Payment Status</h1>
            <p>Your subscription authorisation {status}.</p>
        </div>
    );
}

export default SubscriptionConfirmationPage;
