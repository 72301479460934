import React, { useState } from 'react';
import styles from './SignUp.module.css';
import logo from '../IposLogo.png';
import Select from 'react-select'; // Importing react-select
import { allCountries } from 'country-telephone-data';
import { useLocation } from 'react-router-dom';

// This is the customStyles object. It is defined in JavaScript, not in CSS.
const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: '46.5px',
      minHeight: '40px',
      marginRight: '10px',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '40px',
      padding: '0 10px',
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '40px',
    }),
  };

const WhatsAppSignup = () => {
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryCode, setCountryCode] = useState('44');

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Remove leading zero from the phone number if present
  let cleanPhoneNumber = phoneNumber.startsWith('0') ? phoneNumber.slice(1) : phoneNumber;
console.log('cleanphonenumber', cleanPhoneNumber);
        
        const response = await fetch('/whatsapp/callback', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                username: name, 
                phoneNumber: `+${countryCode}${cleanPhoneNumber}`, 
                messageTime, 
                courseId: 'time-management',
                provider: 'yourFLOCK' 
            }),
        });

        if (response.ok) {
            const data = await response.json();
            window.location.href = data.redirect;
        }
        
    };

    const handleCountrySelect = (selectedOption) => {
        setCountryCode(selectedOption.value);
    };

    // Mapping allCountries to an array of options that react-select can work with
    const countryOptions = allCountries.map(country => ({ value: country.dialCode, label: `${country.name} (+${country.dialCode})` }));
    const currentCountry = countryOptions.find(
        (country) => country.value === countryCode
      );

      const location = useLocation();
const messageTime = location.state.messageTime;

    return (
        <div className={styles.container}>
            <div className={styles.logoSpace}>
                <img src={logo} alt="Logo" className={styles.logo} />
            </div>
            <div className={styles.signupBox}>
                <h2 className={styles.header}>Improve with your AI Coach on WhatsApp</h2>
                
                <form onSubmit={handleSubmit} className={styles.form}>
                    <label className={styles.formLabel}>
                        What's your name?
                        <input type="text" value={name} onChange={e => setName(e.target.value)} required className={styles.formInput}   placeholder="Your name" />
                    </label>
                    
                    <label className={styles.formLabel}>
                        What's your WhatsApp phone number?
                        <div className={styles.phoneNumberContainer}>
                    <Select 
                        options={countryOptions} 
                        value={{ label: `+${countryCode}`, value: `${countryCode}` }} 
                        onChange={handleCountrySelect} 
                        className={styles.countryCode}
                        styles={customStyles}
                        placeholder="Select Country" 
                    />
                    <input type="tel" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} required className={styles.phoneNumber} placeholder="07716866234" />
                </div>
            </label>
                    <div className={styles.buttonWrapper}>
                    <button type="submit" className={styles.whatsappButton}>Sign up with WhatsApp 😍 </button>
                    </div>
                </form>

                <p className={styles.terms}>
                    By signing up for Your Flock, you agree to our 
                    <a href='https://yourflock.co.uk/terms-and-conditions/' target='_blank' rel='noopener noreferrer' className={styles.termsLink}> Terms of Service </a> and 
                    <a href='https://yourflock.co.uk/privacy-policy/' target='_blank' rel='noopener noreferrer' className={styles.termsLink}> Privacy Policy.</a>
                </p>

            </div>
        </div>
    );
};

export default WhatsAppSignup;

