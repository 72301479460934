import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import WhatsAppSignup from './components/WhatsAppSignup';
import WhatsAppSignupHallidays from './components/WhatsAppSignupHallidays';
// import WhatsAppSignupGMW from './components/WhatsAppSignupGMW';
import WhatsAppSignupIpos from './components/WhatsAppSignupIpos';
import SignUp from './components/SignUp';
import SignUpHallidays from './components/SignUpHallidays';
// import SignUpGMW from './components/SignUpGMW';
import SignUpIpos from './components/SignUpIpos';
import PaymentPage from './components/PaymentDetails';
import SubscriptionConfirmationPage from './components/SubscriptionConfirmationPage';

// import Success from './components/Success';

function App() {
  console.log('Setting up routes');
  return (
    <Router>
      <Routes> WhatsAppSignup.js
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signuphallidays" element={<SignUpHallidays />} />
        {/* <Route path="/signupGMW" element={<SignUpGMW />} /> */}
        <Route path="/signupIpos" element={<SignUpIpos />} />
        <Route path="/whatsappsignup" element={<WhatsAppSignup />} />
        <Route path="/whatsappsignuphallidays" element={<WhatsAppSignupHallidays />} />
        {/* <Route path="/whatsappsignupGMW" element={<WhatsAppSignupGMW />} /> */}
        <Route path="/whatsappsignupIpos" element={<WhatsAppSignupIpos />} />
        <Route path="/paymentdetails" element={<PaymentPage />} />
        <Route path="/subscriptionconfirmed" element={<SubscriptionConfirmationPage/>} />

        {/* Add any other routes here */}
      </Routes>
    </Router>
  );
}

export default App;